.hoox-faq {
	padding: 48px 0;
}

.hoox-faq__title {
	margin-bottom: 32px;
}

/*Accordion*/
.hoox-accordion__header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px 0;
	cursor: pointer;
	border-top: 1px solid var(--hoox-color-slate-medium);
}

.hoox-accordion.show-text .hoox-accordion__header {
	padding-bottom: 8px;
}

.hoox-accordion__title {
	color: var(--hoox-color-slate-secondary);
	font-size: 18px;
	font-weight: 400;
	line-height: 150%;
	text-transform: uppercase;
}

.hoox-accordion .hoox-accordion__button {
	transition: all 0.3s ease;
}

.hoox-accordion.show-text .hoox-accordion__button svg path:first-child {
	display: none;
}

.hoox-accordion:nth-child(1) .hoox-accordion__header {
	border-top: unset;
	padding-top: 0;
}

.hoox-accordion__button{
	background: none;
	border: none;
	flex-shrink: 0;
}

.hoox-accordion__panel {
	max-height: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
}

.hoox-accordion__panel p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
	color: var(--hoox-color-slate-secondary);
}

.hoox-accordion__panel ol {
    padding-left: 20px;
}

.hoox-accordion__panel a {
	text-decoration: underline;
}

.hoox-accordion.show-text .hoox-accordion__panel {
    visibility: visible;
    opacity: 1;
    padding: 0 0 16px;
}

@media (min-width: 768px) {
	.hoox-faq {
		padding: 80px 0;
	}

	.hoox-faq__title {
		margin-bottom: 48px;
	}

	.hoox-accordion {
		max-width: 700px;
		margin: auto;
	}
}

@media (max-width: 768px) {
	 .hoox-faq {
		padding-top: 44px;
	}

	 .hoox-faq__items {
		max-width: 325px;
		margin-inline: auto;
	}

	 .hoox-accordion__title {
		letter-spacing: 0.7px;
	}

	

	 .hoox-accordion__panel p {
		padding-right: 45px;
	}

	 .hoox-faq__title {
		margin-bottom: 46px;
	}

	 .hoox-accordion__header {
		padding: 14px 0;
	}
}

 .hoox-faq .hoox-accordion:last-of-type  {
	border-bottom: 1px solid var(--hoox-color-slate-medium);
}

@media (min-width: 1024px) {

	 .hoox-faq__title {
		margin-bottom: 64px;
	}

	 .hoox-accordion__header {
		padding: 14px 0;
	}

	 .hoox-accordion.show-text p {
		margin-top: -5px;
	}
	.hoox-accordion__title br{
		display: none;
	}
	
}

